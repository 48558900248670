@import "_bootstrap-variables";
@import "_bootstrap";
@import "_bootstrap-helpers";

@import "partials/general";
@import "partials/html-editor";
@import "partials/promote-device";
@import "partials/topbar";


:root {
  // JS assigns here the Browser's Scrollbar Width
  --scrollbar-width: 0;
}
