:root {
  --topnav-height: 68px;
}

body {
  min-width: 360px;
}

.body-with-backdrop {
  overflow: hidden;
  padding-right: var(--scrollbar-width);
}


.root {
  display: grid;
  grid-template-rows: 1fr auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}

.container-fluid {
  @extend .mx-auto;
  max-width: 1200px;
  width: 100%;
}

.full-height {
  height: calc(100% - 2rem);
  padding: 1rem;
}


p:last-child {
  margin-bottom: 0;
}


.bg-gradient-multicolor {
  background: linear-gradient(to right top, #eed1ee, #b8c2ed, #89b5ed);
}

.snippet-field-placeholder {
  opacity: .4;
}


.btn {
  text-transform: uppercase;
}

.card-image {
  max-width: 100%;
  height: auto;
}

// stylelint-disable-next-line selector-no-qualifying-type
a.text-decoration-none:hover {
  text-decoration: underline !important;
}


.sticky-stacked-top {
  top: var(--topnav-height, 0);
  z-index: $zindex-sticky - 1;
}

.spacer-between {
  &:not(:first-child) {
    margin-top: 3 * $spacer;
  }
}