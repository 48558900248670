.navbar-user-icon {
  max-height: 28px;
  max-width: 28px;
}

.navbar-user-info {
  font-weight: normal;
  line-height: 1.25;
  text-align: left;
  text-transform: none;
}

.toolbar {
  top: 61px;
  z-index: $zindex-dropdown - 1;
}