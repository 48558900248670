$daily-credits-width: 2rem;

.daily-credits {
  text-align: center;
  width: $daily-credits-width;

  &:first-child {
    margin-left: -(.25 * $daily-credits-width);
  }

  &:last-child {
    margin-right: -(.25 * $daily-credits-width);
  }
}
