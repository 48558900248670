// Color system
$black: black;

$primary: rgb(63, 81, 181);
$warning: #ff7806;
$light: #f0f2f7;


// Options
$enable-shadows: true;
$enable-cssgrid: true;
$enable-negative-margins: true;


// Spacing
$spacer: 1rem;


// Body
$body-bg: $light;
$body-color: rgba($black, .87);


// Components
$border-radius: .5rem;
$border-radius-sm: .3rem;
$border-radius-lg: 1rem;

$box-shadow-sm: rgba($black, .2) 0 2px 1px -1px, rgba($black, .14) 0 1px 1px 0, rgba($black, .12) 0 1px 3px 0;


// Typography
$font-family-sans-serif: "Roboto", "Helvetica", "Arial", sans-serif;

$font-size-base: 1rem;
$font-size-sm: $font-size-base * .8125;

$h1-font-size: $font-size-base * 2;

$text-muted: rgba(black, .54);


// Buttons + Forms
$input-btn-padding-y: .25rem;
$input-btn-padding-x: .63rem;

// Buttons
$btn-font-size: $font-size-sm;

$btn-font-weight: 500;
$btn-box-shadow: $box-shadow-sm;

$btn-border-radius: .25rem;


// Dropdowns
$dropdown-border-radius: $border-radius-sm;


// Cards
$card-border-radius: $border-radius-sm;


// Modals
$modal-footer-margin-between: $spacer;

$modal-content-border-width: 0;
